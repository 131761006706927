import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function settingLotteryDetail (id, type) {
  return request({
    url: `/api/console/settings/lottery_display/${id}`,
    method: 'get',
    params: {
      type: type
    }
  })
}

export function settingLotteryEdit (data, type) {
  return request({
    url: `/api/console/settings/lottery_display/${data.id}`,
    method: 'post',
    params: {
      type: type
    },
    data: getFormData(data)
  })
}

export function settingLotteryAdd (data, type) {
  return request({
    url: '/api/console/settings/lottery_display',
    method: 'post',
    params: {
      type: type
    },
    data: getFormData(data)
  })
}

export function settingLotteryDelete (id, type) {
  return request({
    url: `/api/console/settings/lottery_display/${id}`,
    method: 'delete',
    params: {
      type: type
    }
  })
}

export function settingLotteryChangeStatus (id, status, type) {
  return request({
    url: `/api/console/settings/lottery_display/${id}`,
    method: 'get',
    params: {
      is_visible: status,
      type: type
    }
  })
}

export function settingLotteryHotList (params) {
  return request({
    url: '/api/console/settings/lottery_display',
    method: 'get',
    params: params
  })
}

export function settingLotterList () {
  return request({
    url: '/api/console/lotteries',
    method: 'get'
  })
}

export function settingGet () {
  return request({
    url: '/api/console/settings',
    method: 'get'
  })
}

export function settingIssueResult (data) {
  return request({
    url: '/api/console/settings/issue_result',
    method: 'post',
    data: data
  })
}

export function settingUserCount (data) {
  return request({
    url: '/api/console/settings/user_acc',
    method: 'post',
    data: {
      count: data
    }
  })
}
