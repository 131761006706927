<template>
  <el-card
    class="text-left"
    shadow="never">
    <el-row :gutter="20">
      <el-col :span="3">
        <el-button
          type="primary"
          @click="handleAdd">
          新增
        </el-button>
      </el-col>
      <el-col :span="17">
        <el-form :inline="true" label-width="80px">
          <el-form-item>
            <el-input
              v-model="searchKeyword"
              placeholder="请输入关键字"
              suffix-icon="el-icon-search" />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleRefresh">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: '全部'
      },
      {
        value: '1',
        label: '上架'
      },
      {
        value: '0',
        label: '下架'
      }]
    }
  },
  props: {
    keyword: {
      type: String,
      require: true
    },
    is_visible: {
      type: String,
      require: true
    }
  },
  computed: {
    searchKeyword: {
      set (str) {
        this.handleQuery({
          keyword: str
        })
      },
      get () {
        return this.keyword
      }
    },
    searchVisible: {
      set (str) {
        this.handleQuery({
          is_visible: str
        })
      },
      get () {
        return this.is_visible
      }
    }
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    },
    handleAdd () {
      this.$router.push('lotteryPredict/add')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item{margin-bottom: 0!important;}
</style>